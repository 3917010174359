#tsparticles {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  z-index: -1;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");
* {
  font-family: "Montserrat", sans-serif;
}
#tsparticles canvas {
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity 0.8s ease, -webkit-transform 1.4s ease;
  transition: opacity 0.8s ease, transform 1.4s ease;
}

.izy_rehetra {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 868px) {
  .izy_rehetra {
    zoom: 0.24;
    margin: 0;
  }
  div {
    resize: both;
  }
  div.Table_01 {
    zoom: 100%;
  }
  #tsparticles {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
  }
}

div.Table_01 {
  position: relative;
  left: 0px;
  top: 0px;
  width: 1518px;
  height: 7285px;
}

div.star-v2-01 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1518px;
  height: 3768px;
}

div.star-v2-02 {
  position: absolute;
  left: 0px;
  top: 3768px;
  width: 235px;
  height: 3517px;
}

div.star-v2-03 {
  position: relative;
  left: 235px;
  top: 3768px;
  width: 1049px;
  height: 139px;
}

div.star-v2-04 {
  position: absolute;
  left: 1284px;
  top: 3768px;
  width: 234px;
  height: 3517px;
}

div.star-v2-05 {
  position: absolute;
  left: 235px;
  top: 3907px;
  width: 1049px;
  height: 3378px;
}

.section {
  display: flex;
  padding: 0;
  height: 762px;
  max-width: 100%;
  justify-content: stretch;
}
.center {
  display: flex;
  flex: 1;
}

.left {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
}
.mainty {
  width: 100%;
  background-color: #1f1247;
}

.fotsy {
  width: 50%;
  background-color: red;
}
/*.lefts {
  display: flex;
  position: fixed;
  width: 100px;
  height: 100%;
}

.rights {
  right: 0;
  display: flex;
  position: fixed;
  width: 100px;
  height: 100%;
}*/

.lefts {
  display: flex;
  justify-content: stretch;
  width: 50px;
  min-height: 100%;
}

.rights {
  display: flex;
  width: 50px;
  min-height: 100%;
}

.lohany {
  width: 100%;
  height: 500px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.plane {
  /*background-image: url("../src/img/sra.png");**/

  align-items: center;
  justify-content: center;
  text-align: center;
}

.title__text {
  text-align: center;
  font-size: 63px;
  color: white;
  margin-left: 280px;
  margin-right: 280px;
}

.title__image {
  object-fit: contain;
  width: 100%;
  background-color: red;
}

/*.cockpit {
  margin-left: 200px;
  margin-right: 200px;
  height: 1400px;
  position: relative;
  overflow: hidden;
  text-align: center;
}*/
.pilote {
  height: auto;
  margin-left: 71px;
  margin-right: 71px;
  border-left: 5px solid white;
  border-right: 5px solid white;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background-color: white;
  overflow: hidden;
  resize: none;
  margin-top: 250px;
}
.rambony {
  max-width: 1500px;
  height: 2140px;
  margin-left: 71px;
  margin-right: 71px;
  border-bottom: 20px;
  border-left: 5px solid white;
  border-right: 5px solid white;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  background-color: white;
  overflow: hidden;
  resize: none;
}
.rambonys {
  width: 0;
  height: 0;
  position: relative;
  left: 71px;
  border-left: 689px solid transparent;
  border-right: 689px solid transparent;
  border-top: 1800px solid red;
  z-index: 1;
}
.lastImage {
  position: relative;
  width: 90vw;
  object-fit: contain;
  z-index: 2;
}
.cockpit::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 3000px;
  width: 100%;
  border-radius: 100%;
  border-right: 5px solid white;
  border-left: 5px solid #fff;
  background-color: white;
  z-index: 1;
}
.cabin {
  margin-top: -10px;
  margin-left: 71px;
  margin-right: 71px;
}

.fuselage {
  border-right: 5px solid #fff;
  border-left: 5px solid #fff;
  background-color: white;
}
.profile1 {
  object-fit: contain;
  height: 335px;
  margin-left: 150px;
}

.profile {
  object-fit: contain;
  height: 335px;
  margin-left: auto;
}
.profile2 {
  object-fit: contain;
  height: 335px;
  margin-right: 150px;
}
.profile__description1 {
  text-align: left;
  margin-left: 13vw;
  font-size: 14px;
}

.profile__description {
  text-align: left;
  margin-left: 13vw;
  margin-right: 13vw;
  font-size: 14px;
}

.profile__description2 {
  text-align: left;
  margin-right: 13vw;
  font-size: 14px;
}

.container-fluid {
  padding: 0px !important;
}

.reserver1 {
  margin-top: 80px;
  border-radius: 50px;
  height: auto;
  font-size: 20px;
  width: 30%;
  position: relative;
  z-index: 10;
}
.reserver {
  margin-top: 80px;
  border-radius: 50px;
  height: auto;
  font-size: 20px;
  width: 30%;
}
.c1 {
  position: absolute;
  top: 519px;
  left: 0;
  object-fit: contain;
  z-index: -1;
}
.about {
  margin-top: 100px;
  left: 187px;
}

.etoile {
  width: auto;
}
.rowMIsyEtoile {
  position: absolute;
  top: 206px;
  left: 100px;
  z-index: -1;
  height: 442px;
}
.replay {
  position: relative;
  top: 130px;
  width: auto;
  height: auto;
}
.plane__title {
  font-weight: bold;
  font-size: 3em;
}

.salarie {
  height: 516px;
  position: relative;
  left: 108px;
}
.chef {
  height: 612px;
  position: relative;
  margin-top: 100px;
}
.entrepreneur {
  height: 480px;
  position: relative;
  margin-top: -400px;
}

.leftWing {
  position: absolute;
  top: 1555px;
  left: 0;
  height: 848px;
}

.rightWing {
  position: absolute;
  top: 1555px;
  right: 0;
  height: 848px;
}

.bienvenue {
  font-size: 43px;
  color: #a8abec;
}
.plane {
  position: relative;
}
.space {
  height: 200px;
}
.wc {
  width: 33.33%;
}
.wbl {
  position: absolute;
  left: 0;
  height: 100vh;
}

.wbr {
  position: absolute;
  right: 0;
  height: 100vh;
}
.pic4 {
  height: auto;
  padding-top: 100px;
}
.title_netflix {
  font-size: 25px;
}
.c2 {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 80;
}
.c3 {
  position: absolute;
  bottom: -100px;
  left: 0;
  z-index: 1;
}
.c4 {
  position: absolute;
  bottom: 350px;
  left: 0;
  z-index: 1;
}
.c5 {
  position: absolute;
  bottom: 600px;
  right: 0;
  z-index: 1;
}
.c6 {
  position: absolute;
  top: 600px;
  right: 0;
  z-index: -1;
}

.cloudTop {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  opacity: 0.9;
}

.lastButton {
  position: absolute;
  justify-content: center;
  bottom: 20vh;
  width: 30%;
  margin-top: 80px;
  border-radius: 50px;
  height: auto;
  font-size: 20px;
  left: 35%;
  z-index: 32;
}
.profile__text {
  text-align: justify;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar:hover {
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
.time {
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  background-color: #ef233c;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.time__first {
  color: #fff;
}
.time__first,
.time__second {
  margin-bottom: 0 !important;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
a.bandeau {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");

.count {
  display: inline;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: white !important;
  margin-bottom: 0 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.finished {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.664);
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 9000;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.finished__text {
  font-size: 2.5rem;
  text-align: center;
}
@media (max-width: 1199.98px) {
  .pilote {
    height: 1970px;
    margin-left: 10px;
    margin-right: 10px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    overflow: hidden;
    margin-top: 500px;
  }
  .cabin {
    margin-left: 10px;
    margin-right: 10px;
  }
  .rowMIsyEtoile {
    position: absolute;
    top: 200px;
    left: 0px;
    z-index: -1;
  }

  .profile1,
  .profile {
    height: 250px;
    margin-left: 0px;
  }

  .profile__description1,
  .profile__description {
    margin-left: 100px;
    margin-right: 100px;
  }

  .profile2 {
    height: 250px;
    margin-right: 0px;
  }

  .profile__description2 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .leftWing {
    position: absolute;
    top: 2255px;
    left: 0;
    height: 808px;
  }

  .rightWing {
    position: absolute;
    top: 2255px;
    right: 0;
    height: 808px;
  }

  .salarie {
    left: 0;
  }
  .chef {
    margin-top: 0;
  }
  .entrepreneur {
    margin-top: 0;
  }

  .reserver {
    margin: 10px;
    border-radius: 50px;
    width: 350px;
    font-size: 14px;
  }

  .time {
    height: auto;
    font-size: 10px;
    flex-direction: column;
  }

  .time__first,
  .time__second {
    margin-bottom: 0 !important;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
  .count {
    display: block;
  }

  .finished__text {
    text-align: center;
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  /*.cockpit {
    margin-left: 10px;
    margin-right: 10px;
    height: 500px;
  }*/
  .pilote {
    height: 1530px;
    margin-left: 10px;
    margin-right: 10px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    overflow: hidden;
    margin-top: 200px;
  }

  /*.cockpit::before {
    height: 1000px;
  }*/
  .cabin {
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: -5px;
  }

  .profile1,
  .profile {
    height: 150px;
    margin-left: 0px;
  }

  .profile__description1 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .profile__description {
    margin-left: 20px;
    margin-right: 20px;
  }

  .profile2 {
    height: 150px;
    margin-right: 0px;
  }

  .profile__description2 {
    margin-left: 0;
    width: 100%;
  }

  .rowMIsyEtoile {
    position: absolute;
    left: 0px;
  }
  .plane__title {
    font-size: 1em;
  }

  .rowMIsyEtoile {
    position: absolute;
    top: 100px;
    left: 0px;
    z-index: -1;
  }

  .reserver {
    margin: 10px;
    border-radius: 50px;
    width: 250px;
    font-size: 12px;
  }

  .c1 {
    top: 219px;
  }
  .c3 {
    position: absolute;
    bottom: -50px;
    left: 0;
    z-index: 1;
  }

  .c4 {
    position: absolute;
    bottom: 150px;
    left: 0;
    z-index: 1;
  }

  .c5 {
    position: absolute;
    bottom: 200px;
    right: 0;
    z-index: 1;
  }
  .leftWing {
    position: absolute;
    top: 2055px;
    left: 0;
    height: 388px;
  }

  .rightWing {
    position: absolute;
    top: 2055px;
    right: 0;
    height: 388px;
  }

  .salarie {
    height: 300px;
    left: 0;
  }
  .chef {
    height: 350px;
    margin-top: 0;
  }
  .entrepreneur {
    height: 300px;
    margin-top: 0;
    margin-bottom: -60px;
  }

  .bienvenue {
    font-size: 20px;
    color: #a8abec;
  }
  .space {
    height: 50px;
  }
  .wbl {
    position: absolute;
    left: 0;
    height: 200px;
  }

  .wbr {
    position: absolute;
    right: 0;
    height: 200px;
  }
  .reserver1 {
    width: 180px;
    font-size: 12px;
  }

  .pic4 {
    padding-top: 50px;
  }

  .title_netflix {
    font-size: 14px;
  }
  .lastImage {
    padding-top: -100px;
    width: 95vw;
  }
  .malalaka {
    padding-top: 10px;
  }
}

@media (max-width: 767.98px) {
  .pilote {
    height: 1400px;
    margin-left: 10px;
    margin-right: 10px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    overflow: hidden;
  }

  .profile__description1 {
    margin-left: 0;
    width: 100%;
  }

  .lastButton {
    bottom: 0;
    left: 25%;
    width: 200px;
    height: auto;
    font-size: 12px;
  }
}

@media (max-width: 575.98px) {
  .pilote {
    height: 1150px;
  }

  .cockpit::before {
    height: 1000px;
  }
}

@media (max-width: 320px) {
  .pilote {
    height: 1100px;
  }

  .leftWing {
    position: absolute;
    top: 2055px;
    left: 0;
    height: 288px;
  }

  .rightWing {
    position: absolute;
    top: 2055px;
    right: 0;
    height: 288px;
  }

  .lastButton {
    bottom: 0;
    left: 28%;
    width: 150px;
    height: auto;
    font-size: 12px;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
